import axios from 'axios';
import apiUrls from './apiUrls';

axios.defaults.baseURL = apiUrls[process.env.NODE_ENV];

export async function getTransfersPayment({ id }) {
  const query = `
    query ($id: ID!) {
      node(id: $id) {
        ...on Payment {
          amount
          reference
          signature
          paymentChannel {
            user {
              id
              name
            }
          }
        }
      }
    }
  `;

  return _makeRequest({
    query,
    variables: { id },
    getData: (data) => data.node,
  });
}

export async function refreshTransfersConnections({ userId }) {
  const query = `
    mutation ($user: ID!) {
      refreshTransfersConnections(user: $user)
    }
  `;

  return _makeRequest({
    query,
    variables: { user: userId },
    getData: (data) => data.refreshTransfersConnections,
  });
}

async function _makeRequest({ query, getData, variables, headers }) {
  try {
    const response = await axios.post('/', { query, variables }, { headers });

    const result = {
      success: false,
      errorMessage: null,
      body: null,
    };

    if (response.data.errors) {
      result.errorMessage = response.data.errors[0].message;
    }

    if (response.data.data) {
      result.success = true;
      result.body = getData(response.data.data);
    }

    return result;
  } catch (error) {
    console.log(error);
    return {
      success: false,
      errorMessage: 'Something went wrong.',
      body: null,
    };
  }
}
